import React from "react";
import { Container } from "semantic-ui-react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BodyText from "../components/body-text";

// When redirecting to the site from an external source (ie JotForm)
// We seem to get a brief flash of the 404 page before gatsby serves
// the page we are looking for. This appears to be because Gatsby doesn't
// redirect in the browser on initial render in production.
// https://github.com/gatsbyjs/gatsby/issues/5329
const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  return (
    browser && (
      <Layout>
        <SEO title="404: Not found" />
        <Container>
          <BodyText>
            <p>
              <br />
              <br />
            </p>
            <h1>PAGE NOT FOUND</h1>
            <p>Oops! It seems we missed a beat! </p>
            <p>But don't panic, the show must go on...</p>
            <p>Maybe one of these links will help:</p>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/info-for-parents/">Information for Parents</Link>
              </li>
              <li>
                <Link to="/info-for-schools/">Information for Schools</Link>
              </li>
              <li>
                <Link to="/foundation/">Rocksteady Foundation</Link>
              </li>
              <li>
                <Link to="/careers/">Careers</Link>
              </li>
              <li>
                <Link to="/contact/">Contact</Link>
              </li>
              <li>
                <a href="https://backstage.rocksteadymusicschool.com/sign-in">
                  Login
                </a>
              </li>
            </ul>
          </BodyText>
        </Container>
      </Layout>
    )
  );
};

export default NotFoundPage;
