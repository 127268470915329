import React from "react";
import PropTypes from "prop-types";

import style from "./style.module.css";

const BodyText = ({ children }) => (
  <div className={style.bodyText}>{children}</div>
);

BodyText.propTypes = {
  children: PropTypes.node.isRequired
};

export default BodyText;
